import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
  alertController,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/vue'
import { watch } from 'vue'

// sentry
import * as Sentry from '@sentry/vue'

// composables
import { useErrorHandler } from '@/composables/errorHandler'
import { useStorage } from '@/composables/storage'

// pinia + stores
import { storeToRefs } from 'pinia'
import { useTranscriptStore } from '@/store/transcript'
import { useSpoofingStore } from '@/store/spoofing'
import { useStudentStore } from '@/store/student'


/**
 * props
 * ==================================================================
 */
interface Props {
  activeSectionTitle: string
  canDismiss?: boolean
  isOpen?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalNoAnswers',
  props: {
    activeSectionTitle: {},
    canDismiss: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false }
  },
  emits: ["restart-module", "submit-empty-module"],
  setup(__props: any, { emit: __emit }) {

// ionic + vue
const props = __props

/**
 * events
 * ==================================================================
 */
const emit = __emit

/**
 * composables
 * ==================================================================
 */
// error handler
const { doHandleError } = useErrorHandler()

// spoofing store
const spoofingStore = useSpoofingStore()
const { spoofing } = storeToRefs(spoofingStore)

// storage
const { doGetStorageProperty } = useStorage()

// student store
const studentStore = useStudentStore()
const { student } = storeToRefs(studentStore)

// transcript store
const transcriptStore = useTranscriptStore()
const { transcript, activeExamSection, activeQuestion } =
  storeToRefs(transcriptStore)

/**
 * confirmation
 * ==================================================================
 */
async function doConfirmSubmitNoAnswers () {
  const alert = await alertController.create({
    header: 'Are you sure?',
    message: `This action will submit the module with no answers.
      You will receive a 0 for this module if you proceed.`,
    cssClass: 'alert-confirm-delete',
    buttons: [
      {
        text: 'Submit empty module',
        role: 'confirm',
        handler: () => {
          emit('submit-empty-module')
        }
      },
      {
        text: 'Go back',
        role: 'cancel'
      }
    ]
  })

  await alert.present()
}

/**
 * fetch local storage
 * ==================================================================
 */
 async function doGetStudentLocalStorage () {
  try {
    const activeQuestionIndex =
      await doGetStorageProperty('transcript:activeQuestionIndex')
    const activeSequence =
      await doGetStorageProperty('transcript:activeSequence')
    const transcript =
      await doGetStorageProperty('transcript:transcript')
    return {
      activeQuestionIndex,
      activeSequence,
      transcript
    }
  } catch (error) {
    doHandleError(error as Error, true)
  }
}

/**
 * capture sentry exception
 * ==================================================================
 */
async function doCaptureException () {
  const studentLocalStorage = await doGetStudentLocalStorage()
  const attachmentDataJSON = {
    localStorage: {
      ...studentLocalStorage,
    },
    bugReport: {
      activeExamSection: activeExamSection.value,
      activeQuestion: activeQuestion.value,
      examId: transcript.value?.exam_id,
      isSpoofing: spoofing.value ? true : false,
      studentId: student.value?.id,
      transcriptId: transcript.value?.id
    }
  }
  const sentryAttachmentData = JSON.stringify(attachmentDataJSON)

  Sentry.withScope((scope) => {
    scope.setTag('transcriptId', transcript.value?.id)
    scope.setTag('examId', transcript.value?.exam_id)
    scope.addAttachment({
      filename: `athenaSubmittingNoAnswersReport-student-${
        student.value?.id}.json`,
      data: sentryAttachmentData
    })
    if (!spoofing.value) {
      Sentry.captureException(new Error('Submitting module with no answers'))
    }
  })
}

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen) {
      doCaptureException()
    }
  }
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonModal), {
    class: "modal-no-answers",
    "is-open": props.isOpen,
    "can-dismiss": props.canDismiss
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonHeader), { class: "ion-no-border" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonToolbar), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonTitle), null, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("div", { class: "ion-text-wrap" }, " Something doesn't look right ", -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonGrid), { class: "ion-padding-horizontal" }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonRow), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonCol), null, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, [
                        _createElementVNode("strong", null, _toDisplayString(props.activeSectionTitle), 1),
                        _cache[2] || (_cache[2] = _createTextVNode(" doesn't have any answers filled in. Are you sure you want to submit a module with no answers? "))
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(IonFooter), { class: "ion-no-border ion-padding-horizontal" }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonToolbar), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonButtons), { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonButton), {
                        class: "btn-rounded-close",
                        fill: "solid",
                        color: "red",
                        shape: "round",
                        onClick: doConfirmSubmitNoAnswers
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" Yes, submit 0 answers ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_unref(IonButton), {
                        class: "btn-rounded-close",
                        fill: "solid",
                        color: "green",
                        shape: "round",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('restart-module')))
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" No, restart module timer ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open", "can-dismiss"]))
}
}

})