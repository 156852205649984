import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "btn-container" }

import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPopover
} from '@ionic/vue'
import { helpOutline } from 'ionicons/icons'

// stores
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '@/store/global'

// global store

export default /*@__PURE__*/_defineComponent({
  __name: 'FABReportBug',
  emits: ['open-modal-bug-report'],
  setup(__props, { emit: __emit }) {

const globalStore = useGlobalStore()
const { showPopoverReportBug } = storeToRefs(globalStore)

// emitted events
const emit = __emit

// popover toggle
function doTogglePopover () {
  showPopoverReportBug.value = !showPopoverReportBug.value
}

// open report bug modal
async function doShowReportBugModal () {
  doTogglePopover()
  emit('open-modal-bug-report')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonPopover), {
      "is-open": _unref(showPopoverReportBug),
      mode: "ios",
      side: "top",
      trigger: "click-trigger-popover-bug-report"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonContent), { class: "ion-padding" }, {
          default: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("h5", null, " Experiencing an issue? ", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("div", null, " Send a bug report and help make this application better. ", -1)),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_unref(IonButton), {
                color: "secondary",
                onClick: doShowReportBugModal
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Send Report ")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open"]),
    _createVNode(_unref(IonFab), { horizontal: "end" }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonFabButton), {
          id: "click-trigger-popover-bug-report",
          color: "tertiary",
          size: "small",
          onClick: doTogglePopover
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonIcon), { icon: _unref(helpOutline) }, null, 8, ["icon"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
}

})