import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonModal,
  IonRow,
  IonToolbar
} from '@ionic/vue'
/**
 * props
 * ==================================================================
 */
interface Props {
  isOpen?: boolean
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalAdvanceToNextSection',
  props: {
    isOpen: { type: Boolean, default: false }
  },
  emits: ["next-module"],
  setup(__props: any) {

// ionic + vue


/**
 * events
 * ==================================================================
 */


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonModal), {
    class: "modal-advance",
    "is-open": _ctx.isOpen,
    "can-dismiss": false
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonGrid), { class: "ion-padding-horizontal" }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonRow), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonCol), null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" The time for this section of the test has expired. Click 'OK' to advance to the next section. ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "divider" }, null, -1)),
          _createVNode(_unref(IonFooter), { class: "ion-no-border ion-padding-horizontal" }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonToolbar), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonButton), {
                    class: "ion-no-border",
                    fill: "outline",
                    color: "white",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('next-module')))
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" OK ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}
}

})