type LogFunctionType = 'info'| 'log' | 'error' | 'warn'

export default function doLog (
  message: string,
  type: LogFunctionType | undefined = undefined,
  payload: unknown = undefined
) {
  if (!message || process.env.NODE_ENV === 'test') {
    return
  }

  if (process.env.VUE_APP_DEBUG) {
    switch (type) {
      case 'info':
        console.info(
          message, payload ? ' :>> ' : '', payload || ''
        )
        break
      case 'error':
        console.error(
          message, payload ? ' :>> ' : '', payload || ''
        )
        break
      case 'warn':
        console.warn(
          message, payload ? ' :>> ' : '', payload || ''
        )
        break
      case 'log':
        console.log(
          message, payload ? ' :>> ' : '', payload || ''
        )
        break
      default:
        console.log(
          message, payload ? ' :>> ' : '', payload || ''
        )
        break
    }
  }
}
