import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "alert" }

import {
  IonButton,
  IonButtons,
  IonCol,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonToolbar,
  IonTitle
} from '@ionic/vue'
import { closeOutline } from 'ionicons/icons'
import { computed } from 'vue'

// stores
import { storeToRefs } from 'pinia'
import { useTranscriptStore } from '@/store/transcript'

/**
 * props
 * ==================================================================
 */
interface Props {
  isOpen?: boolean
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalEndSection',
  props: {
    isOpen: { type: Boolean, default: false }
  },
  emits: ["next-module", "close-modal"],
  setup(__props: any, { emit: __emit }) {

// ionic + vue


/**
 * events
 * ==================================================================
 */
const emit = __emit

function closeModal () {
  emit('close-modal')
}

function nextModule () {
  emit('next-module')
}

const transcriptStore = useTranscriptStore()
const { activeTranscriptSection } = storeToRefs(transcriptStore)

const unansweredQuestions = computed(() => {
  return activeTranscriptSection.value?.transcript_details.filter(
    item => !item.student_answer
  ).length || 0
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonModal), {
    class: "modal-advance",
    "is-open": _ctx.isOpen,
    onDidDismiss: closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_unref(IonHeader), { class: "ion-no-border" }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonToolbar), { class: "ion-no-padding" }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonTitle), { class: "ion-padding-horizontal" }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("div", { class: "ion-text-wrap" }, " Continue ", -1)
                  ])),
                  _: 1
                }),
                _createVNode(_unref(IonButtons), { slot: "end" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonButton), {
                      class: "btn-close",
                      onClick: closeModal
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonIcon), {
                          slot: "icon-only",
                          icon: _unref(closeOutline)
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "divider" }, null, -1)),
        _createVNode(_unref(IonGrid), { class: "ion-padding-horizontal" }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonRow), null, {
              default: _withCtx(() => [
                _createVNode(_unref(IonCol), null, {
                  default: _withCtx(() => [
                    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "title" }, " To end the Section, press Continue. ", -1)),
                    _createElementVNode("div", _hoisted_1, " There are " + _toDisplayString(unansweredQuestions.value) + " unanswered question(s). ", 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "divider" }, null, -1)),
        _createVNode(_unref(IonFooter), { class: "ion-no-border ion-padding-horizontal" }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonRow), { class: "ion-justify-content-end" }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonButton), {
                  class: "ion-no-border advance",
                  fill: "outline",
                  color: "white",
                  onClick: nextModule
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Continue ")
                  ])),
                  _: 1
                }),
                _createVNode(_unref(IonButton), {
                  class: "btn-close-footer",
                  color: "dark",
                  fill: "outline",
                  onClick: closeModal
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Close ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["is-open"]))
}
}

})