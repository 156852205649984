import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "btn-container" }

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  modalController
} from '@ionic/vue'
import { computed, ref } from 'vue'

// sentry
import * as Sentry from '@sentry/vue'

// stores
import { storeToRefs } from 'pinia'
import { useSpoofingStore } from '@/store/spoofing'
import { useStudentStore } from '@/store/student'
import { useToastStore } from '@/store/toast'
import { useTranscriptStore } from '@/store/transcript'

// composables
import { useErrorHandler } from '@/composables/errorHandler'
import { useStorage } from '@/composables/storage'

/**
 * composables
 * ==================================================================
 */
// error handler

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalBugReport',
  setup(__props) {

const { doHandleError } = useErrorHandler()

// storage
const { doGetStorageProperty } = useStorage()

/**
 * stores
 * ==================================================================
 */
// spoofing store
const spoofingStore = useSpoofingStore()
const { spoofing } = storeToRefs(spoofingStore)

// student store
const studentStore = useStudentStore()
const { student } = storeToRefs(studentStore)

// toast store
const toastStore = useToastStore()
const { toastData } = storeToRefs(toastStore)

// transcript store
const transcriptStore = useTranscriptStore()
const { transcript, activeExamSection, activeQuestion } =
  storeToRefs(transcriptStore)

/**
 * form
 * ==================================================================
 */
const formData = ref({ description: '' })

const isSubmitDisabled = computed(() => {
  return !formData.value.description
})

// create success toast
function doCreateSuccessToast () {
  toastData.value = {
    isToastVisible: true,
    toastColor: 'success',
    toastDuration: 5000,
    toastIcon: 'checkmarkCircleOutline',
    toastMessage: 'Thank you for submitting a bug report!'
  }
}

// form submit
async function doSubmitBugReport () {
  const studentLocalStorage = await doGetStudentLocalStorage()
  const attachmentDataJSON = {
    localStorage: {
      ...studentLocalStorage,
    },
    bugReport: {
      activeExamSection: activeExamSection.value,
      activeQuestion: activeQuestion.value,
      bugDescription: formData.value.description,
      examId: transcript.value?.exam_id,
      isSpoofing: spoofing.value ? true : false,
      studentId: student.value?.id,
      transcriptId: transcript.value?.id
    }
  }
  const sentryAttachmentData = JSON.stringify(attachmentDataJSON)

  Sentry.withScope((scope) => {
    scope.setTag('transcriptId', transcript.value?.id)
    scope.setTag('examId', transcript.value?.exam_id)
    scope.addAttachment({
      filename: `athenaBugReport-student-${student.value?.id}.json`,
      data: sentryAttachmentData
    })
    Sentry.captureException(new Error('Student Bug Report'))
    doCreateSuccessToast()
  })

  await doDismissModal()
}

/**
 * fetch local storage
 * ==================================================================
 */
async function doGetStudentLocalStorage () {
  try {
    const activeQuestionIndex =
      await doGetStorageProperty('transcript:activeQuestionIndex')
    const activeSequence =
      await doGetStorageProperty('transcript:activeSequence')
    const transcript = await doGetStorageProperty('transcript:transcript')
    const transcriptContent =
      await doGetStorageProperty('transcript:transcriptContent')
    return {
      activeQuestionIndex,
      activeSequence,
      transcript,
      transcriptContent
    }
  } catch (error) {
    doHandleError(error as Error, true)
  }
}

/**
 * modal control
 * ==================================================================
 */
// close modal
async function doDismissModal () {
  await modalController.dismiss()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonHeader), null, {
      default: _withCtx(() => [
        _createVNode(_unref(IonToolbar), null, {
          default: _withCtx(() => [
            _createVNode(_unref(IonTitle), null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Report a Bug ")
              ])),
              _: 1
            }),
            _createVNode(_unref(IonButtons), { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonButton), {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (doDismissModal()))
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Close ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(IonContent), { class: "ion-padding" }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonCard), null, {
          default: _withCtx(() => [
            _createVNode(_unref(IonCardHeader), null, {
              default: _withCtx(() => [
                _createVNode(_unref(IonCardSubtitle), null, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Describe What Happened * ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(IonCardContent), null, {
              default: _withCtx(() => [
                _createVNode(_unref(IonTextarea), {
                  modelValue: formData.value.description,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.description) = $event)),
                  "auto-grow": true,
                  fill: "outline",
                  rows: 10,
                  name: "description"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(IonCard), null, {
          default: _withCtx(() => [
            _createVNode(_unref(IonCardHeader), { class: "card-debug-header" }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonCardSubtitle), null, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" Debug Information ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(IonCardContent), null, {
              default: _withCtx(() => [
                _createVNode(_unref(IonGrid), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonRow), null, {
                      default: _withCtx(() => [
                        _cache[7] || (_cache[7] = _createElementVNode("span", null, "Student ID:", -1)),
                        _createTextVNode("  " + _toDisplayString(_unref(student)?.id), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(IonRow), null, {
                      default: _withCtx(() => [
                        _cache[8] || (_cache[8] = _createElementVNode("span", null, "Transcript ID:", -1)),
                        _createTextVNode("  " + _toDisplayString(_unref(transcript)?.id || 'N/A'), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(IonRow), null, {
                      default: _withCtx(() => [
                        _cache[9] || (_cache[9] = _createElementVNode("span", null, "Exam ID:", -1)),
                        _createTextVNode("  " + _toDisplayString(_unref(transcript)?.exam_id || 'N/A'), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[11] || (_cache[11] = _createElementVNode("span", null, "* Required Field", -1)),
          _createVNode(_unref(IonButton), {
            color: "secondary",
            disabled: isSubmitDisabled.value,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (doSubmitBugReport()))
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode(" Submit Report ")
            ])),
            _: 1
          }, 8, ["disabled"])
        ])
      ]),
      _: 1
    })
  ], 64))
}
}

})