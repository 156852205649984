import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx } from "vue"

import {
  IonApp,
  IonRouterOutlet,
  IonToast,
  loadingController,
  modalController
} from '@ionic/vue'
import {
  alertCircleOutline,
  checkmarkCircleOutline
} from 'ionicons/icons'
import {
  computed,
  nextTick,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  watch
} from 'vue'

// route
import { useRoute } from 'vue-router'

// composables
import { useNavigateToNextModule } from '@/composables/navigateToNextModule'
import { useTheme } from '@/composables/theme'
import { useTimer } from '@/composables/timer'

// pinia + stores
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { useGlobalStore } from '@/store/global'
import { useToastStore } from '@/store/toast'
import { useTranscriptStore } from '@/store/transcript'

// components
import ModalBugReport from '@/components/bug-report/ModalBugReport.vue'
import ModalEndSection from './components/exam-player/ModalEndSection.vue'
import ModalMobile from '@/components/shared/ModalMobile.vue'
import ModalNoAnswers from './components/shared/ModalNoAnswers.vue'
import ModalAdvanceToNextSection from './components/shared/ModalAdvanceToNextSection.vue'
import FABReportBug from '@/components/bug-report/FABReportBug.vue'

// auth store

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

// ionic + vue
const authStore = useAuthStore()
const { isLoggedIn } = storeToRefs(authStore)

// global store
const globalStore = useGlobalStore()
const {
  isTestDayModeEnabled,
  showModalAdvanceToNextSection,
  showModalEndSection,
  showModalNoAnswers
} = storeToRefs(globalStore)

// transcript store
const transcriptStore = useTranscriptStore()
const { examName, sectionTitle } = storeToRefs(transcriptStore)

// toaster
const toastStore = useToastStore()
const { toastData } = storeToRefs(toastStore)

const toasterIcon = computed((): string => {
  switch (toastData.value.toastIcon) {
  case 'alertCircleOutline':
    return alertCircleOutline
  case 'checkmarkCircleOutline':
    return checkmarkCircleOutline
  default:
    return ''
  }
})

function setShowToast (isToastVisible: boolean) {
  toastData.value.isToastVisible = isToastVisible
}

// timer
const {
  doResetTimer,
  doInitializeCountdown,
  doStartTimer
} = useTimer()

// theme
const { doSetTheme } = useTheme()

// ModalNoAnswers state
const canDismissModalNoAnswers = ref(false)

// hide mobile modal for small screens
const showMobileModal = ref(false)
function setShowMobileModal () {
  showMobileModal.value = window.innerWidth <= 600 || window.innerHeight <= 500
}

// refreshing state
const isRefreshing = ref(false)

// route
const route = useRoute()
const canRefreshOnUpdate = computed(() => {
  const currentRoute = route.path
  return (
    currentRoute === '/configure' ||
    currentRoute === '/start'
  )
})

// service worker
const swRegistration = ref<ServiceWorkerRegistration | null>(null)

function doSetSWRegistration (event: CustomEvent<ServiceWorkerRegistration>) {
  // store service worker registration
  swRegistration.value = event.detail
  // send message to SW to skip waiting and activate new SW
  swRegistration.value.waiting?.postMessage({
    type: 'SKIP_WAITING'
  })
}

function doWindowReload () {
  if (isRefreshing.value || !canRefreshOnUpdate.value) {
    return
  }
  isRefreshing.value = true
  window.location.reload()
}

// restart current module
async function doRestartModule () {
  canDismissModalNoAnswers.value = true
  await doResetTimer()
  await doInitializeCountdown()
  doStartTimer()
  showModalNoAnswers.value = false
  await nextTick()
  canDismissModalNoAnswers.value = false
}

// score module without any answers
const { doNavigateToNextModule } = useNavigateToNextModule()

function doAdvance () {
  showModalAdvanceToNextSection.value = false
  showModalEndSection.value = false
  doNavigateToNextModule(true)
}

async function doScoreExamWithNoAnswers () {
  const loading = await loadingController.create({
    message: 'Scoring module...',
    spinner: 'bubbles',
    cssClass: 'scoring-module-loading'
  })
  loading.present()
  canDismissModalNoAnswers.value = true
  await doNavigateToNextModule(true)
  showModalNoAnswers.value = false
  loading.dismiss()
  canDismissModalNoAnswers.value = false
}

// keyboard shortcut to toggle isTestDayModeEnabled for QA and devs
function handleTestDayModeShortcut (
    { altKey, ctrlKey, key, repeat }: KeyboardEvent
  ) {
  if (repeat) {
    return
  }
  if (altKey && ctrlKey && key === '.') {
    isTestDayModeEnabled.value = !isTestDayModeEnabled.value
    if (isTestDayModeEnabled.value) {
      toastData.value = {
        isToastVisible: true,
        toastColor: 'success',
        toastDuration: 2000,
        toastIcon: 'checkmarkCircleOutline',
        toastMessage: 'Test day mode is enabled'
      }
    } else {
      toastData.value = {
        isToastVisible: true,
        toastColor: 'danger',
        toastDuration: 2000,
        toastIcon: 'alertCircleOutline',
        toastMessage: 'Test day mode is disabled'
      }
    }
  }
}

// lifecycle hooks
onBeforeMount(() => {
  document.addEventListener(
    'serviceWorkerUpdated',
    (
      (event: CustomEvent<ServiceWorkerRegistration>) => {
        doSetSWRegistration(event)
      }
    ) as EventListener,
    { once: true }
  )

  if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener(
      'controllerchange', doWindowReload
    )
  }
})

// bug report modal
async function doShowBugReportModal () {
  const modal = await modalController.create({
    component: ModalBugReport
  })
  modal.present()
}

// theme
watch(
  examName,
  () => {
    doSetTheme()
  },
  { immediate: true }
)

onMounted(async () => {
  // subscribe to authStore, redirect to `plato` if not logged in
  authStore.$subscribe(() => {
    if (!isLoggedIn.value) {
      window.open(`${process.env.VUE_APP_STUDENT_URL}/exams`, '_self')
    }
  })

  document.addEventListener('keydown', handleTestDayModeShortcut)

  setShowMobileModal()
  window.addEventListener('resize', () => {
    setShowMobileModal()
  })
})

onUnmounted(async () => {
  document.removeEventListener('keydown', handleTestDayModeShortcut)

  document.removeEventListener('serviceWorkerUpdated', (
      (event: CustomEvent<ServiceWorkerRegistration>) => {
        doSetSWRegistration(event)
      }
    ) as EventListener
  )

  if (navigator.serviceWorker) {
    navigator.serviceWorker.removeEventListener(
      'controllerchange', doWindowReload
    )
  }

  await swRegistration.value?.unregister()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonApp), null, {
    default: _withCtx(() => [
      (showMobileModal.value)
        ? (_openBlock(), _createBlock(ModalMobile, { key: 0 }))
        : _createCommentVNode("", true),
      (_unref(showModalAdvanceToNextSection))
        ? (_openBlock(), _createBlock(ModalAdvanceToNextSection, {
            key: 1,
            "is-open": _unref(showModalAdvanceToNextSection),
            onNextModule: doAdvance
          }, null, 8, ["is-open"]))
        : _createCommentVNode("", true),
      _createVNode(ModalEndSection, {
        "is-open": _unref(showModalEndSection),
        onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (showModalEndSection.value = false)),
        onNextModule: doAdvance
      }, null, 8, ["is-open"]),
      _createVNode(ModalNoAnswers, {
        "is-open": _unref(showModalNoAnswers),
        "can-dismiss": canDismissModalNoAnswers.value,
        "active-section-title": _unref(sectionTitle),
        onRestartModule: doRestartModule,
        onSubmitEmptyModule: doScoreExamWithNoAnswers
      }, null, 8, ["is-open", "can-dismiss", "active-section-title"]),
      _createVNode(_unref(IonToast), {
        "is-open": _unref(toastData).isToastVisible,
        color: _unref(toastData).toastColor,
        duration: _unref(toastData).toastDuration,
        icon: toasterIcon.value,
        message: _unref(toastData).toastMessage,
        position: "top",
        onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (setShowToast(false)))
      }, null, 8, ["is-open", "color", "duration", "icon", "message"]),
      _createVNode(_unref(IonRouterOutlet), { animated: "false" }),
      _createVNode(FABReportBug, {
        onOpenModalBugReport: _cache[2] || (_cache[2] = ($event: any) => (doShowBugReportModal()))
      })
    ]),
    _: 1
  }))
}
}

})