import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ion-text-center" }
const _hoisted_2 = { class: "ion-margin-bottom" }
const _hoisted_3 = { style: {"padding":"77.22% 0 0 0","position":"relative"} }
const _hoisted_4 = ["src", "title"]

import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonModal,
  IonRow
} from '@ionic/vue'
import { desktopOutline } from 'ionicons/icons'
import { onMounted } from 'vue'

// config
import {
  mobileModalImageItems,
  mobileModalVideoItems
} from '@/config/mobileModalItems'
import { useTranscriptStore } from '@/store/transcript'
import { storeToRefs } from 'pinia'

// extend window interface to include vimeo
interface Window {
  Vimeo: any
}

// stores

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalMobile',
  setup(__props) {

// ionic + vue
const transcriptStore = useTranscriptStore()
const { examName } = storeToRefs(transcriptStore)

onMounted(() => {
  // add vimeo script to head once on page load
  if (!(window as unknown as Window)?.Vimeo) {
    const vimeoScript = document.createElement('script')
    vimeoScript.setAttribute('src', 'https://player.vimeo.com/api/player.js')
    document.head.appendChild(vimeoScript)
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonModal), {
    "is-open": true,
    "can-dismiss": false
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonGrid), { class: "container" }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonRow), { class: "ion-margin-top" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonCol), { class: "ion-text-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), { icon: _unref(desktopOutline) }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonRow), { class: "ion-justify-content-center" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonCol), { size: "8" }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createElementVNode("div", { class: "ion-text-center" }, " This page should be viewed on desktop for the best possible experience. ", -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_unref(examName) && _unref(examName) !== 'ACT')
                ? (_openBlock(), _createBlock(_unref(IonRow), {
                    key: 0,
                    class: "ion-margin-top ion-justify-content-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonCol), { size: "11" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            _cache[1] || (_cache[1] = _createElementVNode("h2", null, " What you will see: ", -1)),
                            _cache[2] || (_cache[2] = _createElementVNode("div", null, " We’ve created an exact replica of the official Digital SAT and PSAT experience. It’s been optimized for larger screens so that you’ll be able to: ", -1)),
                            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mobileModalImageItems), (item) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: item.imgAlt
                              }, [
                                _createVNode(_unref(IonImg), {
                                  src: item.imgSrc,
                                  alt: item.imgAlt
                                }, null, 8, ["src", "alt"]),
                                _createElementVNode("label", null, _toDisplayString(item.label), 1)
                              ]))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_unref(examName) && _unref(examName) !== 'ACT')
                ? (_openBlock(), _createBlock(_unref(IonRow), {
                    key: 1,
                    class: "ion-margin-top ion-justify-content-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonCol), {
                        size: "12",
                        class: "ion-text-center"
                      }, {
                        default: _withCtx(() => [
                          _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Videos", -1)),
                          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "ion-padding-bottom" }, " The features and tools within the demo are an exact replica of the official Digital SAT and PSAT exams. Experience it yourself! ", -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mobileModalVideoItems), (item) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: item.heading
                            }, [
                              _createElementVNode("h4", _hoisted_2, _toDisplayString(item.heading), 1),
                              _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("iframe", {
                                  src: item.iframeSrc,
                                  frameborder: "0",
                                  allow: "picture-in-picture",
                                  style: {"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},
                                  title: item.iframeTitle
                                }, null, 8, _hoisted_4)
                              ])
                            ]))
                          }), 128)),
                          _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                          _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})