// route
import { useRoute } from 'vue-router'

// pinia + store
import { storeToRefs } from 'pinia'
import { useTranscriptStore } from '@/store/transcript'

export function useTheme () {
  // route
  const route = useRoute()

  // transcript store
  const transcriptStore = useTranscriptStore()
  const { examName } = storeToRefs(transcriptStore)

  function doSetTheme () {
    if (route.name === 'Configure') {
      // Always use default theme on Configure page
      document.body.classList.remove('digital-act')
      document.body.classList.add('digital-sat')
      return
    }
    switch (examName.value) {
      case 'ACT':
        document.body.classList.remove('digital-sat')
        document.body.classList.add('digital-act')
        break
      default:
        document.body.classList.remove('digital-act')
        document.body.classList.add('digital-sat')
        break
    }
  }

  return {
    doSetTheme
  }
}
